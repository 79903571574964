<template>
  <div
    v-show="getPopupViewStatus"
    class=" fixed inset-x-0 bottom-0 z-50 flex  bg-gray-50 shadow-2xl rounded-t-md "
    :class="
      `${isFullView ? 'max-h-82vh' : 'h-10'} ${
        getSmallScreenStatus ? 'mx-5 w-auto' : 'ml-16 w-96'
      }`
    "
  >
    <div class="w-full">
      <loading
        v-if="!isFullView"
        :active="isLoading"
        :height="25"
        :opacity="0"
        :color="`#fff`"
        :loader="`bars`"
        :is-full-page="false"
        :class="`outline-none`"
      />
      <div
        class="h-10 bg-gray-900 rounded-t-md flex justify-between items-center px-4"
      >
        <div class="text-oWhite font-medium">
          <p>Requested Rent ({{ getData.length }})</p>
        </div>
        <div class="flex gap-4 items-center justify-center">
          <div class="cursor-pointer" @click="toggleView">
            <i
              class="text-base fas text-oWhite"
              :class="
                `${
                  isFullView
                    ? 'fa-down-left-and-up-right-to-center'
                    : 'fa-up-right-and-down-left-from-center'
                }`
              "
            />
          </div>
        </div>
      </div>
      <div class="px-2 relative">
        <loading
          v-if="isFullView"
          :active="isLoading"
          :height="25"
          :opacity="0.8"
          :color="`#000`"
          :loader="`bars`"
          :is-full-page="false"
          :class="`outline-none`"
        />

        <div
          class="pt-2   overflow-auto max-h-80vh"
          :class="getData.length > 1 ? 'pb-10' : 'pb-3'"
        >
          <div :key="itemIndex" v-for="(item, itemIndex) in getData">
            <RentRequestCard :rawData="item" :cardPosition="itemIndex" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RentRequestCard from '@/components/popup/rent-request/RentRequestCard.vue'
import { secToDHMSConverter } from '@/components/picker/date-range/utils/DatesUtils.js'
import ReconnectingWebsocket from 'reconnectingwebsocket'
import { SocketConfig } from '@/config/SocketConfig'

export default {
  name: 'RentRequestPopup',
  components: {
    RentRequestCard,
  },
  data() {
    return {}
  },
  mounted() {
    this.startListener()
  },
  beforeDestroy() {
    this.removeListener()
  },

  computed: {
    isLoading() {
      return this.$store.getters['rentReqPopupActionScreen/getReqStatus']
    },
    getPopupViewStatus() {
      return this.$store.getters['rentReqPopupActionScreen/getPopViewStatus']
    },

    isFullView: {
      get() {
        return this.$store.getters['rentReqPopupActionScreen/getFullViewStatus']
      },
      set(val) {
        this.$store.commit('rentReqPopupActionScreen/SET_FULL_VIEW_STATUS', val)
      },
    },

    getData() {
      return this.$store.getters['rentReqPopupActionScreen/getData']
    },

    getSmallScreenStatus() {
      return this.$store.getters[
        'rentReqPopupActionScreen/getSmallScreenStatus'
      ]
    },
  },
  watch: {
    getData: {
      deep: true,
      immediate: false,
      handler(data) {
        if (data.length === 0) {
          this.$store.commit(
            'rentReqPopupActionScreen/SET_POPUP_VIEW_STATUS',
            false
          )
          this.$store.commit(
            'rentReqPopupActionScreen/SET_FULL_VIEW_STATUS',
            false
          )
        }
      },
    },
  },
  methods: {
    secToDHMSConverter,
    toggleView() {
      this.isFullView = !this.isFullView
    },
    startListener() {
      let token = localStorage.getItem('token') || null
      if (!token) return
      let sockUrl =
        SocketConfig.baseUrl +
        SocketConfig.channels.tripUpdates +
        `?token=${token}`

      this.sockListener = this.sockListener
        ? this.sockListener
        : new ReconnectingWebsocket(sockUrl, null, SocketConfig.configs())

      this.sockListener.onopen = () => {
        console.log('Opened Noti Connection...')
      }
      this.sockListener.onclose = () => {
        console.log('Closed Noti Connection...')
      }
      this.sockListener.onmessage = this.onMessageHandler
    },
    removeListener() {
      if (this.sockListener) {
        this.sockListener.close(
          4003,
          'oto system closed the socket as the was closed'
        )
        this.sockListener = null
      }
    },
    onMessageHandler(message) {
      let payload = JSON.parse(message.data)
      if (payload.n_type !== 'unlock_request') return

      this.$store.commit('rentReqPopupActionScreen/INJECT_DATA', payload.data)
      this.$store.commit('rentReqPopupActionScreen/SET_POPUP_VIEW_STATUS', true)
      this.$store.commit('rentReqPopupActionScreen/SET_FULL_VIEW_STATUS', true)

      console.log('Incoming --> ', payload.data)
    },
  },
}
</script>
<style lang="scss">
.max-h-82vh {
  max-height: 82vh;
}
.max-h-80vh {
  max-height: 80vh;
}
</style>
