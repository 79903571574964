<template>
  <div class="card">
    <div class="header">
      <div class="item-info grid grid-cols-2">
        <p class="typography-one">Rider</p>
        <p class="typography-two">
          <router-link
            class="font-bold text-blue-600 capitalize cursor-pointer"
            :to="{
              name: 'ViewRiderUserProfile',
              params: { id: data.user.id },
            }"
            target="_blank"
          >
            {{ data.user.full_name }}
          </router-link>
        </p>
      </div>
      <div class="item-info grid grid-cols-2">
        <p class="typography-one">Vehicle QR</p>
        <p class="typography-two">
          <router-link
            class="font-bold text-blue-600 capitalize cursor-pointer"
            :to="{
              name: 'ViewRiderUserProfile',
              params: { id: data.bike.id },
            }"
            target="_blank"
          >
            {{ data.bike.qr_code }}
          </router-link>
        </p>
      </div>
      <div class="item-info grid grid-cols-2">
        <p class="typography-one">Pickup Time</p>
        <p class="typography-two">
          {{ getFormattedDateTime(data.pick_up_time, 'h:mm:ss a,D MMM YY') }}
        </p>
      </div>
      <div class="item-info grid grid-cols-2">
        <p class="typography-one">Pickup Location</p>
        <p class="typography-two">{{ data.pick_up_location }}</p>
      </div>
      <div class="item-info grid grid-cols-2">
        <p class="typography-one">Estimated Time</p>
        <p class="typography-two">
          <span class="text-oGreen">{{
            secToDHMSConverter(data.estimated_riding_time)
          }}</span>
        </p>
      </div>
    </div>
    <div class="footer">
      <section class="flex items-center py-4 px-3  space-x-5 ">
        <anchor-button
          variant="warning-light"
          :class="`w-1/2`"
          :isLoading="data.is_rent_request_rejecting"
          @click="
            onUnlockRequestAction({
              action: 'reject',
            })
          "
        >
          Reject
        </anchor-button>

        <anchor-button
          variant="success"
          :class="`w-1/2`"
          :isLoading="data.is_rent_request_accepting"
          @click="
            onUnlockRequestAction({
              action: 'accept',
            })
          "
        >
          Accept
        </anchor-button>
      </section>
    </div>
  </div>
</template>
<script>
import { secToDHMSConverter } from '@/components/picker/date-range/utils/DatesUtils.js'
import { getFormattedDateTime } from '@/utils/datetime'

export default {
  name: 'RentRequestCard',
  props: {
    rawData: {
      type: Object,
    },
    cardPosition: {
      type: Number,
    },
  },
  components: {
    AnchorButton: () => import('@/components/form/AnchorButton.vue'),
  },
  watch: {
    rawData: {
      immediate: true,
      deep: true,
      handler(data) {
        if (data) {
          this.data = {
            ...data,
            is_rent_request_accepting: false,
            is_rent_request_rejecting: false,
          }
        }
      },
    },
  },
  data() {
    return {
      data: null,
    }
  },

  methods: {
    secToDHMSConverter,
    getFormattedDateTime,
    async onUnlockRequestAction({ action = 'accept' } = {}) {
      if (this.$acl.canNotUpdate('trips')) {
        this.$notify(this.$acl.notAbleToToast())
        return
      }
      if (action === 'accept') {
        this.data.is_rent_request_accepting = true
      }
      if (action === 'reject') {
        this.data.is_rent_request_rejecting = true
      }

      let url
      if (action === 'accept') {
        url = `/dashboard/trips/${this.data.id}/accept-unlock-request/`
      }
      if (action === 'reject') {
        url = `/dashboard/trips/${this.data.id}/reject-unlock-request/`
      }

      await this.$http
        .put(url)
        .then((r) => {
          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: 'Success',
              text: `${r.data.message}`,
            },
            5000
          )
          console.log('test-onUnlockRequestAction', r.data)
        })
        .catch((err) => {
          this.$notify(
            {
              group: 'bottomRight',
              type: 'error',
              title: `Failed [${err.response.status}]`,
              text: `${err.response.data.detail || 'Failed to execute'}`,
            },
            5000
          )
          console.warn('onUnlockRequestActionErr', { err })
        })
        .finally(() => {
          if (action === 'accept') {
            this.data.is_rent_request_accepting = false
          }
          if (action === 'reject') {
            this.data.is_rent_request_rejecting = false
          }
          this.$store.commit(
            'rentReqPopupActionScreen/REMOVE_DATA_ITEM',
            this.cardPosition
          )
          setTimeout(() => {
            this.$store.dispatch('fsTable/fetchData')
          }, 5000)
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.card {
  min-width: 200px;
  height: auto;
  box-shadow: 0 4px 11px 0 #3134692c;
  @apply bg-white rounded-lg m-2;

  .header {
    @apply p-3;
  }
  .footer {
    border-top: 1px solid;
    @apply mt-3 border-gray-200;
  }
  .item-info {
    @apply py-1;
  }
}

.typography-one {
  @apply font-semibold text-14px text-gray-500;
}
.typography-two {
  @apply font-medium text-14px  text-oDark;
}
</style>
